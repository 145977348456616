import {createTheme} from '@mui/material/styles';
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      default: "#DAE9FE"
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#187BAC",
      // dark: will be calculated from palette.primary.main,
      contrastText: "#fff" //will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#3DAF8C",
      // dark: will be calculated from palette.primary.main,
      contrastText: "#fff" //will be calculated to contrast with palette.primary.main
    },
  },
  colorPreset: 'green',
  // direction: settings.direction,
  // paletteMode: settings.paletteMode,
  contrast: 'high',
  typography,
});

export default theme;
