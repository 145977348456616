import {lazy, Suspense} from "react";
import {Routes, Route} from "react-router-dom";
import Layout from "../layouts/Layout";
import {app_path, paths} from "../app/paths";

const Homepage = lazy(() => import("../pages/Homepage"));
const ConfirmAccount = lazy(() => import("../pages/ConfirmAccount"));

export default function RootRouter() {
    return (
        <Routes>
            <Route path={paths.index} element={<Layout/>}>
                {/* public routes */}
                <Route path={paths.confirm_account} element={<ConfirmAccount/>}/>
                <Route path={paths.index} element={<Homepage/>}/>
            </Route>
        </Routes>
    );
}
