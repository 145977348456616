import RootRouter from "./router/RootRouter";
import './App.css';
import {ThemeProvider} from "@mui/material/styles";
import theme from "./theme/muiTheme";
import CssBaseline from '@mui/material/CssBaseline';
import {Toaster} from "react-hot-toast";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <RootRouter/>
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
        </ThemeProvider>
    );
}

export default App;
