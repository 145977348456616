export const app_path = 'app';

export const paths = {
    index: '/',
    confirm_account: '/confirm-account/:unique'
};

export function baseUrl(path) {
    return `/${path}`;
}

export function appUrl(path) {
    return `/${app_path}/${path}`;
}
